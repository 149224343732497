.card {
    margin-top: 50px;
    margin-left:15px;
    margin-right: 15px;
    width: 250px;
    /* box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
    height: 300px;
    border-radius: 30px;
    backdrop-filter: blur(50px);
    /* filter:blur(4px); */
    background: rgb(2, 0, 36);
    /* background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.05) 0%,
      #00f5c966 0%,
      rgba(255, 255, 255, 0.05) 70%
    ); */
    padding: 10px;
    text-align: center;
    object-fit: cover;
    cursor: pointer;
  }
  .nft-image {
    width: 240px;
    height: 170px;
    margin-top: 5px;
    padding-right: 10px;
    border-radius: 20px;
  }
  
  .card{
      animation:cardHoverAnimation 5s;
      /* animation-iteration-count: infinite; */
      
      
  }
  
  .card:focus{
      outline: none;
  }
  
  .wrapper{
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      /* border:1px solid purple; */
      margin-left: 10px;
      margin-right: 10px;
  }
  
  .info-container{
  
      /* border:1px solid red; */
  
  }
  
  .info-container > p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      
      text-align: left;
  }
  
  .info-container > .owner {
        
      font-size:16px;
      text-align: center;
      font-weight: normal;
      color:#C6C2C6;
      padding-top: 10px;
  }
  
  .info-container > .name {
      font-family: mars;
      font-size: 25px;
      text-align: center;
      font-weight: normal;
      color: #ffffff;
      padding-top:20px;
  }
  
  .price-container{
      /* border:1px solid green; */
  
  }
  
  .price-container > p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      color:#666c70;
      text-align:left;
  }
  
  .price-container > .price-label{
      font-size:12px;
      font-weight: bold;
      color:#C6C2C6;
      padding-top: 5px;
  }
  
  .price-container > .price {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      padding-top:10px;
  }
  
  .buttons{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 35px;
  }
  
  .buy-now {
      width: 80px;
      height: 30px;
      margin-left: 10px;
      border:none;
      background-color: transparent;
      color:#ffffff;
      border:1px solid #ffffff;
      border-radius: 15px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      cursor:pointer;
  }
  
  .buy-now:hover{
      color:black;
      background-color: #ffffff;
  }
  
  .like-container{
      display: flex;
      flex-direction: row;
      margin-right: 10px;
      text-align:center;
  }
  
  .like{
      /* width: 30px; */
      /* height: 30px; */
      margin-right: 10px;
      background-color: transparent;
      border:none;
      cursor:pointer;
      display:flex;
      flex-direction: row;
  }
  
  .like-count{
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size:15px;
      margin-top:5px;
      color:#ffffff;
  }
  
  
  @keyframes cardHoverAnimation {
      0% {
          background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(0, 245, 201, 0.4) 0%,
      rgba(255, 254, 254, 0.05) 70%
    );
      }
  
      100%{
          background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(0, 0, 0, 0.425) 0%,
      rgba(0, 0, 0, 0.05) 70%
    );
      }
  }
  
  model-viewer{
      width:240px;
      height:170px;
  }
  
  model-modelviewerelement{
      width:240px;
      height:170px;
  }