
    .iconBadge:hover {
        background-color: #024481 !important;
        color: white !important;


}


    .iconBadge{
        background-color: white !important;
        color: #024481 !important;
        text-align: center !important;

    }
