
    .mintButton:hover {
        background-color: #d00087 !important;
        color: white !important;


}


    .mintButton{
        background-color: white !important;
        color: #d00087 !important;
        border-radius: none !important;
    }

