
    .navbarButton:hover {
        background-color: #d00087 !important;
        color: white !important;


}


    .navbarButton{
        background-color: white !important;
        color: #d00087!important;
    }
