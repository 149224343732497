
    .metamob:hover {
        background-color: #024481 !important;
        color: white !important;


}


    .metamob{
        background-color: #0760b5 !important;
        color: white !important;
        border-radius: none !important;
    }
