.default-button {
    border:none;
    background-color: transparent;
    color:#ffffff;
    border:1px solid #ffffff;
    border-radius: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    cursor:pointer;
    padding:2px;
}

.default-button:hover{
    color:black;
    background-color: #ffffff;
}